<template>
  <div class="database-home">
    <IndexHeader />
    <div class="database-home-content" v-loading="loading">
      <transition name="el-fade-in-linear">
        <div class="header-info">
          <div class="second-left">
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ allCount }}</span>
                <span class="infoTitle">患者数</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ operationCount }}</span>
                <span class="infoTitle">手术数</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ mCount }}</span>
                <span class="infoTitle">男</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ wCount }}</span>
                <span class="infoTitle">女</span>
              </div>
            </transition>
          </div>
          <div class="second-right">
            <div class="rightContent">
              <el-button
                :type="queryType == 'All' ? 'primary' : 'text'"
                @click="chartIsShow('全部')"
              >
                全部
              </el-button>
            </div>
            <div class="rightContent">
              <el-button
                :type="queryType == 'Year' ? 'primary' : 'text'"
                @click="chartIsShow('今年')"
              >
                今年
              </el-button>
            </div>
            <div class="rightContent">
              <el-button
                :type="queryType == 'Month' ? 'primary' : 'text'"
                @click="chartIsShow('本月')"
              >
                本月
              </el-button>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div class="chartContent">
          <div class="itemCard" v-for="(item, index) in moduleList" :key="index">
            <el-card :body-style="{ padding: '0.9375rem 0.9375rem' }" v-show="item.count > 0">
              <div slot="header" class="clearfix">
                <span class="cardTitle">{{ item.title }}: {{ item.count }}</span>
              </div>
              <el-descriptions :column="6" :colon="false" style="padding: 0 10px">
                <el-descriptions-item v-for="(ele, eleIndex) in item.examineList" :key="eleIndex">
                  <template slot="label">
                    <span class="examineListTitle">
                      {{ ele.DICTNAME }}
                      ({{ ele.NUM }}例)
                    </span>
                  </template>
                </el-descriptions-item>
              </el-descriptions>
              <div :id="'chartBoxHeight' + index" class="chartBoxHeight">
                <transition name="el-fade-in-linear">
                  <div class="chartBox">
                    <el-card
                      shadow="never"
                      class="chartInfo"
                      :body-style="{ padding: '0.625rem 0.9375rem 0' }"
                    >
                      <div class="mouthChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo"
                      :body-style="{ padding: '0.625rem 0.9375rem 0' }"
                    >
                      <div class="pieChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo"
                      :body-style="{ padding: '0.625rem 0.9375rem 0' }"
                    >
                      <div class="ageChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo"
                      :body-style="{ padding: '0.3125rem 0.9375rem 0' }"
                    >
                      <div class="mapChart" :id="'mapChart' + index"></div>
                      <el-button
                        class="areaBack"
                        :id="'areaBack' + index"
                        style="display: none"
                        type="primary"
                        size="small"
                        @click="getReturnMap(item, index)"
                      >
                        返回
                      </el-button>
                      <div class="leftMapTitle">
                        <div
                          class="mapTitleItem"
                          v-for="(area, areaIndex) in item.areaList"
                          :key="areaIndex"
                        >
                          <span :id="'cityShow' + index">{{ area.CITY }}: {{ area.NUM }}</span>
                          <span :id="'countyShow' + index" style="display: none">
                            {{ area.COUNTY }}: {{ area.NUM }}
                          </span>
                        </div>
                        <span v-show="item.areaList.length > 0">
                          其他省份: {{ item.otherAreaCount }}
                        </span>
                      </div>
                    </el-card>
                    <div class="btn-list">
                      <el-button
                        type="primary"
                        size="default"
                        class="commonBtn"
                        @click="cardInfoOpen(item)"
                      >
                        统计
                      </el-button>
                      <el-button
                        type="primary"
                        size="default"
                        class="commonBtn"
                        @click="cardInfoOpen(item, 'query')"
                      >
                        检索
                      </el-button>
                      <el-button
                        type="primary"
                        size="default"
                        class="commonBtn"
                        @click="toFollow(item)"
                      >
                        随访管理
                      </el-button>
                      <el-button
                        type="primary"
                        size="default"
                        class="commonBtn"
                        style="padding: 10px 5px"
                        @click=""
                      >
                        导出数据库
                      </el-button>
                    </div>
                  </div>
                </transition>
              </div>
            </el-card>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import IndexHeader from '../../components/IndexHeader.vue'
// 各省份对应的矢量数据地址
import { getProvinceMapInfo } from '../../utils/map-utils'
const { city } = require('province-city-china/data')
export default {
  name: 'Complex_database',
  components: {
    IndexHeader
  },
  data() {
    return {
      loading: true,
      queryType: 'All',
      allCount: '',
      mCount: '',
      operationCount: '',
      wCount: '',
      moduleList: [],
      mapName: '', // 当前map名称
      mapInitialName: '',
      mapChart: '',
      mapInfo: '',
      mapJson: ''
    }
  },
  created() {
    this.getCountPatients()
  },
  watch: {},
  methods: {
    toFollow(item) {
      const { href } = this.$router.resolve({
        path: `/FollowHome?moduleCode=${item.moduleCode}`
      })
      window.open(href, '_blank')
    },
    cardInfoOpen(item, type) {
      if (type === 'query') {
        const { href } = this.$router.resolve({
          path: `/AdvancedSearch?moduleCode=${item.moduleCode}`
        })
        window.open(href, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: `/Database/StatisticalInfo?title=${item.title}&moduleId=${item.moduleId}&moduleCode=${item.moduleCode}`
        })
        window.open(href, '_blank')
      }
    },
    getCountPatients() {
      this.moduleList = []
      this.$api.get(`/v1/webconsole/healthdb/countPatients?queryType=${this.queryType}`).then(
        (res) => {
          if (res.data && res.data.status == 200) {
            this.loading = false
            this.allCount = res.data.data.allCount
            if (this.allCount == 0) {
              this.infoAllCountIsEmpty = false
              this.mCount = 0
              this.operationCount = 0
              this.wCount = 0
              if (this.queryType == 'Year') {
                this.$message.error('本年度暂无信息')
              } else if (this.queryType == 'Month') {
                this.$message.error('本月度暂无信息')
              }
            } else {
              this.mCount = res.data.data.mCount
              this.operationCount = res.data.data.operationCount
              this.wCount = res.data.data.wCount
              this.mapName = res.data.data.province || '浙江省'
              this.mapInitialName = res.data.data.province || '浙江省'
              res.data.data.moduleList?.forEach((item) => {
                if (item.count > 0) {
                  this.moduleList.push({
                    ...item
                  })
                }
              })
              for (let i = 0; i < this.moduleList.length; i++) {
                const element = this.moduleList[i]
                this.$nextTick(() => {
                  this.draw_mapItemChart(element, i)
                })
              }
              this.$nextTick(() => {
                this.draw_mouthBar()
                this.draw_doctorLine()
                this.draw_ageLine()
              })
            }
          } else {
            this.loading = false
          }
        },
        () => {
          this.loading = false
        }
      )
    },
    async getMapCityJson(name) {
      let jsonData = await import('../../assets/js/citys/' + name + '.json')
      return jsonData
    },
    getReturnMap(item, index) {
      this.mapName = this.mapInitialName
      this.draw_mapItemChart(item, index)
      document.getElementById('areaBack' + index).style.display = 'none'
    },
    draw_mouthBar() {
      let moduleList = this.moduleList
      // 基于准备好的dom，初始化echarts实例
      var mouthCharts = document.getElementsByClassName('mouthChart')
      // 循环元素
      for (var i = 0; i < moduleList.length; i++) {
        let xAxisData = []
        let yAxisData = []
        let chartTitle = []
        moduleList[i].monthList.forEach((item, index) => {
          let ele = []
          chartTitle.push(item.year)
          for (let j = 0; j < item.data.length; j++) {
            const element = item.data[j]
            if (xAxisData.length < 12) {
              xAxisData.push(element.month + '月')
            }
            ele.push(element.count)
          }
          yAxisData.push({
            name: chartTitle[index],
            type: 'bar',
            data: ele
          })
        })
        // 初始化每一个图表
        var myChart = echarts.init(mouthCharts[i]) // 绘制图表
        var Option = {
          title: {
            text: '月份统计'
          },
          tooltip: {},
          legend: {
            type: 'scroll',
            left: 80
          },
          xAxis: {
            data: xAxisData
          },
          yAxis: {
            type: 'value',
            minInterval: 1
          },
          series: yAxisData
        }
        myChart.setOption(Option)
      }
    },
    draw_ageLine() {
      let moduleList = this.moduleList
      var ageCharts = document.getElementsByClassName('ageChart')
      // 循环元素
      for (var i = 0; i < moduleList.length; i++) {
        let ageData = []
        moduleList[i].ageList.forEach((item) => {
          let nameNum = item.title + '(' + item.count + ')'
          ageData.push({
            value: item.count,
            name: nameNum
          })
        })
        // 初始化每一个图表
        var ageChart = echarts.init(ageCharts[i]) // 绘制图表
        var Option = {
          title: {
            text: '手术年龄分布'
          },
          tooltip: {},
          legend: {
            // orient: 'vertical',
            type: 'scroll',
            orient: 'vertical',
            top: '24',
            left: '-5',
            data: ageData
          },
          series: [
            {
              type: 'pie',
              data: ageData,
              radius: '60%',
              center: ['60%', '55%'],
              // minAngle: 15, //最小角度
              // startAngle: 270, //起始角度
              labelLine: {
                length: 5
              },
              label: {
                position: 'outer',
                alignTo: 'labelLine',
                fontSize: 12
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            }
          ]
        }
        ageChart.setOption(Option)
      }
    },
    draw_doctorLine() {
      let moduleList = this.moduleList
      // 基于准备好的dom，初始化echarts实例
      var doctorCharts = document.getElementsByClassName('pieChart')
      // 循环元素
      for (var i = 0; i < moduleList.length; i++) {
        let pieData = []
        moduleList[i].doctorList.forEach((item) => {
          let nameNum = item.DOCTOR + '(' + item.NUM + ')'
          pieData.push({
            value: item.NUM,
            name: nameNum
          })
        })
        // 初始化每一个图表
        var doctorChart = echarts.init(doctorCharts[i]) // 绘制图表
        var Option = {
          title: {
            text: '主刀医生'
          },
          tooltip: {},
          legend: {
            // orient: 'vertical',
            type: 'scroll',
            orient: 'vertical',
            top: '24',
            left: '-5',
            data: pieData
          },
          series: [
            {
              type: 'pie',
              data: pieData,
              radius: '70%',
              center: ['60%', '50%'],
              // minAngle: 15, //最小角度
              startAngle: 270, //起始角度
              labelLine: {
                length: 5
              },
              label: {
                position: 'outer',
                alignTo: 'labelLine',
                fontSize: 12
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            }
          ]
        }
        doctorChart.setOption(Option)
      }
    },
    async draw_mapItemChart(item, index, type) {
      // 基于准备好的dom，初始化echarts实例
      this.mapChart = echarts.getInstanceByDom(document.getElementById('mapChart' + index)) //有的话就获取已有echarts实例的DOM节点。
      if (this.mapChart == null || '') {
        // 如果不存在，就进行初始化。
        this.mapChart = echarts.init(document.getElementById('mapChart' + index))
      }
      let areaList = item.areaList
      let mapData = []
      if (type == 'province') {
        document.getElementById('cityShow' + index).style.display = 'none'
        document.getElementById('countyShow' + index).style.display = 'block'
        for (var l = 0; l < city.length; l++) {
          if (this.mapName === city[l].name) {
            let newMapJson = await this.getMapCityJson(city[l].code)
            echarts.registerMap(this.mapName, newMapJson)
            areaList.forEach((item) => {
              mapData.push({
                value: item.NUM,
                name: item.COUNTY
              })
            })
            break
          }
        }
      } else {
        if (document.getElementById('cityShow' + index)) {
          document.getElementById('cityShow' + index).style.display = 'block'
          document.getElementById('countyShow' + index).style.display = 'none'
        }
        this.mapInfo = getProvinceMapInfo(this.mapName)
        if (this.mapInfo?.key != undefined) {
          this.mapJson = require('../../assets/js/province/' + this.mapInfo?.key + '.json')
        }
        echarts.registerMap(this.mapName, this.mapJson)
        areaList.forEach((item) => {
          mapData.push({
            value: item.NUM,
            name: item.CITY
          })
        })
      }

      var Option = {
        backgroundColor: 'transparent',
        title: {
          text: '地区分布'
        },
        // 提示框组件
        tooltip: {
          show: true,
          // 触发类型：数据项图形触发
          trigger: 'item',
          padding: 10,
          borderWidth: 1,
          borderColor: '#409eff',
          backgroundColor: 'rgba(255,255,255,0.4)',
          // 提示框内容格式器
          formatter: function (params) {
            if (params.value !== params.value) {
              params.value = 0
            }
            let context = `
                   <div>
                       <p style="line-height: 30px; font-weight: 600">${params.name}</p>
                       <p><span>地区数量 : </span><span>${params.value}</span></p>
                   </div>
                `
            return context
          }
        },
        visualMap: {
          show: false,
          min: 1,
          max: 1000,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        geo: {
          map: this.mapName,
          roam: false, // 一定要关闭拖拽
          zoom: 1.25,
          label: {
            show: true, // 省份名展示
            fontSize: '10',
            color: 'rgba(0,0,0,0.8)'
          },
          emphasis: {
            itemStyle: {
              areaColor: '#2B91B7'
            }
          }
        },
        series: [
          {
            type: 'map',
            map: this.mapName,
            geoIndex: 0, // 解决设置geo后地图重影问题
            zoom: 1.25, // 这里是关键，一定要放在 series中
            data: mapData
          }
        ]
      }
      // 绘制图表
      this.mapChart.setOption(Option)
      // 设置点击事件
      if (areaList.length > 0) {
        if (type == 'province') {
          this.mapChart.off('click')
        } else {
          this.mapChart.on('click', (e) => {
            this.mapName = e.name
            if (e.componentSubType == 'map') {
              this.showProvince(item, index, 'province')
            }
          })
        }
      }
    },
    showProvince(item, index, type) {
      document.getElementById('areaBack' + index).style.display = 'block'
      this.draw_mapItemChart(item, index, type)
    },
    // 数据可视化
    chartIsShow(val) {
      if (val == '全部') {
        this.queryType = 'All'
      }
      if (val == '今年') {
        this.queryType = 'Year'
      }
      if (val == '本月') {
        this.queryType = 'Month'
      }
      this.loading = true
      this.getCountPatients()
    },
    setScrollBar() {
      var div = document.querySelector('.database-home-content')
      div.style.height = window.innerHeight - 62 + 'px'
      div.style.width = window.innerWidth + 'px'
    }
  },
  mounted() {
    this.setScrollBar()
    // 浏览器缩放，更新ScrollBar位置
    window.addEventListener('resize', this.setScrollBar)
  }
}
</script>

<style scoped lang="less">
.el-input .el-select {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.database-home-content::-webkit-scrollbar {
  width: 12px;
}
.database-home-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.database-home-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.database-home-content::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.database-home-content {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #f5f8fa;
  .header-info {
    background-color: #fff;
    display: flex;
    padding: 0 30px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 2px #ccc;
    align-items: center;
    justify-content: space-between;
    .second-left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .leftContent {
        width: 120px;
        padding: 5px 10px;
        margin: 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        border: 1px dashed #e4e6ef;
        border-radius: 5px;
        .infoNum {
          color: red;
        }
        .infoTitle {
          line-height: 1;
        }
      }
    }
    .second-right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      .rightContent {
        width: 120px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .chartContent {
    background-color: #f5f8fa;
    .itemCard {
      .cardTitle {
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
      }
      .examineListTitle {
        font-size: 16px;
      }
      .clearfix:before,
      .clearfix:after {
        display: table;
        content: '';
      }
      .clearfix:after {
        clear: both;
      }
      .chartBoxHeight {
        height: 300px;
      }
      .chartBox {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-list {
          height: 300px;
          position: relative;
          display: flex;
          flex-direction: column;
          .el-button + .el-button {
            margin: 0;
            margin-top: 10px;
          }
        }
        .chartInfo {
          width: 24%;
          height: 300px;
          position: relative;
          .mouthChart {
            width: 100%;
            height: 290px;
          }
          .pieChart {
            width: 100%;
            height: 290px;
          }
          .ageChart {
            width: 100%;
            height: 290px;
          }
          .mapChart {
            width: 100%;
            height: 290px;
          }

          .frequencyChart {
            width: 100%;
            height: 290px;
          }
          .areaChart {
            width: 100%;
            height: 290px;
          }
          .leftMapTitle {
            position: absolute;
            height: 235px;
            width: 100px;
            top: 47px;
            left: 2px;
            display: flex;
            flex-direction: column;
            .mapTitleItem {
              width: 88px;
              display: flex;
              flex-wrap: wrap;
            }
          }
          .areaBack {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      @media screen and (max-width: 1180px) {
        .chartBoxHeight {
          height: 644px;
          .btn-list {
            display: inline-block;
            .el-button {
              margin-top: 8px;
              margin-right: 8px;
            }
            .el-button + .el-button {
              margin: 0;
              margin-top: 8px;
              margin-right: 8px;
            }
          }
        }
        .chartBox {
          flex-wrap: wrap;
          .chartInfo {
            width: 49%;
          }
        }
      }
    }
  }
}
</style>
