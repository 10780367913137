import { render, staticRenderFns } from "./Complex_database.vue?vue&type=template&id=8f3f6650&scoped=true"
import script from "./Complex_database.vue?vue&type=script&lang=js"
export * from "./Complex_database.vue?vue&type=script&lang=js"
import style0 from "./Complex_database.vue?vue&type=style&index=0&id=8f3f6650&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f3f6650",
  null
  
)

export default component.exports